import App from '@app/App';
import { Outlet } from 'react-router';

const BlankLayout = () => {
  return (
    <App>
      <div className="dark:text-white-dark min-h-screen text-black">
        <Outlet />
      </div>
    </App>
  );
};

export default BlankLayout;
