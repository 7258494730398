import BlankLayout from '@layouts/BlankLayout';
import DefaultLayout from '@layouts/DefaultLayout';
import ErrorElement from '@pages/ErrorElement.tsx';
import { AppRoutes, InterfaceAppRoute } from '@router/AppRoutes.tsx';
import { createBrowserRouter } from 'react-router-dom';

const finalRoutes = Object.values(AppRoutes).map((route: InterfaceAppRoute) => {
  return {
    path: '/',
    element: route.layout === 'blank' ? <BlankLayout /> : <DefaultLayout />,
    children: [route],
    errorElement: <ErrorElement />,
  };
});

const Router = createBrowserRouter(finalRoutes);

export default Router;
