import { MantineThemeOverride } from '@mantine/core';

export interface InterfaceAppConfig {
  app: {
    name: string;
    shortName: string;
    description: string;
    backEndUrl: string;
    defaultRoute: string;
    loggedInDefaultRoute: string;
  };
  weather: {
    apiKey: string;
  };
  mantine: {
    defaultColorScheme: 'light' | 'dark';
    mantineThemeOverride: MantineThemeOverride;
  };
}

const defaultFonts = 'Montserrat, sans-serif';

const AppConfig: InterfaceAppConfig = {
  app: {
    name: 'TrackLab',
    shortName: 'TrackLab', // Your application's short_name. `string`. Optional. If not set, appName will be used
    description: '',
    backEndUrl: '/api/v1',
    defaultRoute: '/',
    loggedInDefaultRoute: '/Dashboard',
  },
  weather: {
    apiKey: import.meta.env.VITE_OPEN_WEATHER_MAPS_API_KEY ?? '',
  },
  mantine: {
    defaultColorScheme: 'light',
    mantineThemeOverride: {
      fontFamily: defaultFonts,
    },
  },
};

export default AppConfig;
