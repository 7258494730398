import { useAppDispatch, useAppSelector } from '@store/appStore.ts';
import { getCompanyCollectors, selectCompanyCollectorsByFarm } from '@store/reducers/collectorReducer.ts';
import { getOptionValueFromSlug, getOptionsArrayFromUuidOrSlug } from '@utils/OptionsArrayHelpers.ts';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export type CollectorSelectProps = {
  farmUuid: string;
  setCollectorUuid: React.Dispatch<React.SetStateAction<string>>;
  initialValue: string;
  disabled?: boolean;
};

export default function CollectorSelect({
  farmUuid,
  setCollectorUuid,
  initialValue = '',
  disabled = false,
}: CollectorSelectProps) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCompanyCollectors());
  });

  const companyCollectors = useAppSelector(selectCompanyCollectorsByFarm(farmUuid));
  const companyCollectorOptions = getOptionsArrayFromUuidOrSlug(companyCollectors);
  const [companyCollectorUuid, setCompanyCollectorUuid] = useState<string>(initialValue);
  const companyCollectorsOptionValue = getOptionValueFromSlug(companyCollectorOptions, companyCollectorUuid, false);
  return (
    <>
      <Select
        className="z-50"
        id="companyCollectorUuid"
        placeholder="Select collector"
        required={true}
        options={companyCollectorOptions}
        value={companyCollectorsOptionValue}
        // isDisabled={disabled}
        noOptionsMessage={() => (disabled ? 'Please select farm' : 'No options')}
        onChange={(option) => {
          const selectedValue = option?.value ?? '';
          setCompanyCollectorUuid(selectedValue);
          setCollectorUuid(selectedValue);
        }}
      />
    </>
  );
}
