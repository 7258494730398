import MachineInfo from '@components/collector/MachineInfo';
// import CollectorAngleDisplay from '@components/CollectorAngleDisplay/CollectorAngleDisplay.tsx';
import solarFarmIcon from '@assets/images/solar_farm_icon.svg';
import unitsIcon from '@assets/images/units.svg';
import CollectorGraphMeasurementWrapper from '@components/CollectorGraphMeasurementWrapper/CollectorGraphMeasurementWrapper.tsx';
import CollectorMeasurementSelect from '@components/CollectorMeasurementSelect/CollectorMeasurementSelect.tsx';
import CollectorSelect from '@components/CollectorSelect/CollectorSelect.tsx';
import FarmSelect from '@components/FarmSelect/FarmSelect.tsx';
import WeatherComponent from '@components/weather/WeatherComponent';
import { checkIfActionFulfilled } from '@lib/axiosClientCached.ts';
import { AppRoutes } from '@router/AppRoutes.tsx';
import { useAppDispatch, useAppSelector } from '@store/appStore.ts';
import {
  getCompanyAggregators,
  getCompanyAllCollectorAvailableMeasurements,
  getCompanyAllCollectorParams,
  getCompanyCollectors,
  getCompanyFarms,
  selectCompanyCollectors,
  // selectNumberOfCollectorsForFarm,
  selectNumberOfCollectors,
  selectNumberOfFarms,
} from '@store/reducers/collectorReducer.ts';
import { getMe, selectMe } from '@store/reducers/meReducer.ts';
import { meCheckIfLoggedIn } from '@store/reducers/reducerHelpers/meCheckIfLoggedIn.ts';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { URLSearchParamsInit } from 'react-router-dom/dist/dom';
import useAsyncEffect from 'use-async-effect';
// import CombinedCollectorGraph from '@components/CollectorGraphMeasurements/CombinedCollectorGraphMeasurements';

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const me = useAppSelector(selectMe);

  const companyCollectors = useAppSelector(selectCompanyCollectors);
  // setCompanyCollector(useAppSelector(selectCompanyCollector(companyCollectorUuid)));

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useAsyncEffect(async () => {
    const resultAction = await dispatch(meCheckIfLoggedIn());
    const result = await checkIfActionFulfilled(resultAction, meCheckIfLoggedIn);
    if (!result) {
      navigate(AppRoutes.signIn.path);
    }
  });

  const [companyFarmUuid, setCompanyFarmUuid] = useState<string>(searchParams.get('companyFarmUuid') ?? '');
  const [companyCollectorUuid, setCompanyCollectorUuid] = useState<string>(
    searchParams.get('companyCollectorUuid') ?? '',
  );
  const [dataMeasurementTypeSlug, setDataMeasurementTypeSlug] = useState<string>(
    searchParams.get('dataMeasurementTypeSlug') ?? '',
  );

  useEffect(() => {
    if (me) {
      dispatch(getCompanyFarms());
      dispatch(getCompanyAggregators());
      dispatch(getCompanyCollectors());
      dispatch(getCompanyAllCollectorParams());
      dispatch(getCompanyAllCollectorAvailableMeasurements());
    }
  }, [me, dispatch]);

  useEffect(() => {
    const searchParms: URLSearchParamsInit = {};
    if (companyFarmUuid !== '') {
      searchParms.companyFarmUuid = companyFarmUuid;
    }
    if (companyCollectorUuid !== '') {
      searchParms.companyCollectorUuid = companyCollectorUuid;
    }
    if (dataMeasurementTypeSlug !== '') {
      searchParms.dataMeasurementTypeSlug = dataMeasurementTypeSlug;
    }
    setSearchParams(searchParms);
  }, [setSearchParams, companyFarmUuid, companyCollectorUuid, dataMeasurementTypeSlug]);

  const numberOfFarms = useAppSelector(selectNumberOfFarms);
  // const numberOfCollectors = useAppSelector((state) => selectNumberOfCollectorsForFarm(state, companyFarmUuid));
  const numberOfCollectors = useAppSelector(selectNumberOfCollectors);

  return (
    <div className="container mx-auto">
      {/* <h1 className="mb-4 text-2xl font-bold">Dashboard</h1> */}
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className="info_panel p-4 sm:ml-64">
        <div className="rounded-lg border-2 border-dashed border-gray-200 p-4 dark:border-gray-700">
          <div className="mb-4 grid items-center">
            <div className="grid grid-cols-4 grid-rows-1 gap-4">
              <div className="flex items-center">
                <img src={solarFarmIcon} alt="Solar Farm" style={{ width: '100px' }} />
                <div>
                  <h5 className="tl-large-heading">Sites</h5>
                  <div> Farms: {numberOfFarms}</div>
                </div>
              </div>
              <div className="flex items-center">
                <img src={unitsIcon} alt="Tracker Unit" style={{ width: '38px', marginRight: '20px' }} />
                <div>
                  <h5 className="tl-large-heading">Units</h5>
                  <div> Collectors: {numberOfCollectors}</div>
                </div>
              </div>
              <div>{/* <h5 className="tl-large-heading">Capacity</h5> */}</div>
              <div> </div>
            </div>
          </div>

          <div className="mb-4 grid grid-cols-2 gap-4">
            <div className="flex h-28 items-center justify-center rounded bg-gray-50 dark:bg-gray-800">
              <div className="text-2xl text-gray-400 dark:text-gray-500">Generation Analysis combined</div>
              {/* <CombinedCollectorGraph farmUuid={companyFarmUuid} /> */}
            </div>
            <div className="flex h-28 items-center justify-center rounded bg-gray-50 dark:bg-gray-800">
              <div className="text-2xl text-gray-400 dark:text-gray-500">Output Analysis combined</div>
            </div>
          </div>
        </div>
      </div>

      <div className="site_level_analysis p-4 sm:ml-64">
        <div className="mb-28 rounded-lg border-2 border-dashed border-gray-200 p-4 dark:border-gray-700">
          <div className="items-left justify-lef mb-4 flex rounded bg-gray-50 px-3 py-3 dark:bg-gray-800">
            <div className="text-2xl text-gray-400 dark:text-gray-500">
              <div>Site Level Analysis Selection</div>
            </div>
          </div>
          <div className="grid grid-cols-3 grid-rows-1 gap-4">
            <div className="basic-card items-left mb-4 flex flex-col">
              <h5 className="tl-sm-heading mr-1">Select Site</h5>
              <FarmSelect setFarmUuid={setCompanyFarmUuid} initialValue={companyFarmUuid} />
            </div>
            <div className="basic-card items-left mb-4 flex flex-col">
              <h5 className="tl-sm-heading mr-1">Select Collector </h5>
              <CollectorSelect
                farmUuid={companyFarmUuid}
                setCollectorUuid={setCompanyCollectorUuid}
                initialValue={companyCollectorUuid}
                disabled={companyFarmUuid === ''}
              />
            </div>
            <div className="basic-card items-left mb-4 flex flex-col">
              <h5 className="tl-sm-heading mr-4">Select Data</h5>
              <CollectorMeasurementSelect
                collectorUuid={companyCollectorUuid}
                setDataMeasurementTypeSlug={setDataMeasurementTypeSlug}
                initialValue={dataMeasurementTypeSlug}
                disabled={companyFarmUuid === ''}
              />
            </div>
          </div>

          {companyCollectorUuid !== '' && companyCollectors[companyCollectorUuid] !== undefined ? (
            <>
              <div className="basic-card mb-4 grid grid-rows-1 gap-4">
                <div className="col-span-4">
                  <h5 className="tl-medium-heading">Selected Collector</h5>
                  <h5 className="text-sm font-semibold text-tlOrange">
                    {companyCollectors[companyCollectorUuid].name}
                  </h5>
                  {/* <div className="text-gray-500 dark:text-gray-400">TESTINGGGGGG</div> */}
                </div>
                <WeatherComponent collector={companyCollectors[companyCollectorUuid]} />
              </div>

              <div className="basic-card mb-4 flex items-center justify-center">
                <p className="text-2xl text-gray-400 dark:text-gray-500">Machine Info</p>
                <MachineInfo collector={companyCollectors[companyCollectorUuid]} />
              </div>

              <div className="basic-card mb-4">
                {dataMeasurementTypeSlug ? (
                  <CollectorGraphMeasurementWrapper
                    collector={companyCollectors[companyCollectorUuid]}
                    dataMeasurementTypeSlug={dataMeasurementTypeSlug}
                  />
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
