import { useAppDispatch, useAppSelector } from '@store/appStore';
import { getCompanyFarms, selectCompanyFarms } from '@store/reducers/collectorReducer.ts';
import { getOptionValueFromSlug, getOptionsArrayFromUuidOrSlug } from '@utils/OptionsArrayHelpers';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export interface FarmSelectProps {
  setFarmUuid: React.Dispatch<React.SetStateAction<string>>;
  initialValue: string;
}

export default function FarmSelect({ setFarmUuid, initialValue = '' }: FarmSelectProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCompanyFarms());
  });

  const companyFarms = useAppSelector(selectCompanyFarms);
  const companyFarmsOptions = getOptionsArrayFromUuidOrSlug(companyFarms);
  const [companyFarmUuid, setCompanyFarmUuid] = useState<string>(initialValue);
  const companyFarmsOptionValue = getOptionValueFromSlug(companyFarmsOptions, companyFarmUuid, false);

  return (
    <>
      <Select
        className="z-50"
        id="companyFarmUuid"
        placeholder="Select farm"
        required={true}
        options={companyFarmsOptions}
        value={companyFarmsOptionValue}
        onChange={(option) => {
          const selectedValue = option?.value ?? '';
          setCompanyFarmUuid(selectedValue);
          setFarmUuid(selectedValue);
        }}
      />
    </>
  );
}
