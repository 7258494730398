import { InterfaceCollectorMeasurements } from '@interfaces/InterfaceCollectorMeasurements.ts';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

type TimeUnits = 'day' | 'hour';

export type CollectorGraphMeasurementsProps = {
  collectorMeasurements: InterfaceCollectorMeasurements;
};

const getData = (collectorMeasurements: InterfaceCollectorMeasurements, period: string): ChartData<'line'> => {
  const dataset: number[] = [];
  const labels: string[] = [];
  Object.keys(collectorMeasurements.data).forEach((date) => {
    if (shouldIncludeDate(date, period)) {
      labels.push(date);
      dataset.push(Number(collectorMeasurements.data[date]));
    }
  });
  return {
    labels,
    datasets: [
      {
        label: `${collectorMeasurements.measurementTypeSlug} (${period})`,
        data: dataset,
        fill: true,
        backgroundColor: 'rgba(247, 148, 29,0.6)',
        borderColor: 'rgba(10, 34, 59,1)',
        borderWidth: 0.5,
      },
    ],
  };
};

const shouldIncludeDate = (date: string, period: string): boolean => {
  const now = new Date();
  const dateObj = new Date(date);
  switch (period) {
    case 'day':
      return now.getTime() - dateObj.getTime() <= 24 * 60 * 60 * 1000;
    case 'week':
      return now.getTime() - dateObj.getTime() <= 7 * 24 * 60 * 60 * 1000;
    case 'month':
      return now.getTime() - dateObj.getTime() <= 30 * 24 * 60 * 60 * 1000;
    default:
      return true;
  }
};

export default function CollectorGraphMeasurements({ collectorMeasurements }: CollectorGraphMeasurementsProps) {
  const [period, setPeriod] = useState('month');

  const data = useMemo(() => getData(collectorMeasurements, period), [collectorMeasurements, period]);

  const [unit, setUnit] = useState<TimeUnits>('day');

  const options: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: unit,
          displayFormats: {
            day: 'MMM D',
            hour: 'HH:mm',
          },
          tooltipFormat: 'YY/MM/DD HH:mm',
          round: 'minute',
        },
        ticks: {
          source: 'auto',
          autoSkip: true,
        },
        title: {
          display: true,
          text: 'DATE',
          color: '#f7941d',
          font: {
            family: 'Helvetica',
            size: 12,
            lineHeight: 1.2,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'VALUE',
          color: '#f7941d',
          font: {
            family: 'Helvetica',
            size: 12,
            lineHeight: 1.2,
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
      },
      legend: {
        display: false, // Hide the default legend
      },
    },
  };

  return (
    <div>
      <div className="graph-buttons-timeframe flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="mr-4 size-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
          />
        </svg>

        <button className="graph-button" onClick={() => setPeriod('month')}>
          1 Month
        </button>
        <button className="graph-button" onClick={() => setPeriod('week')}>
          1 Week
        </button>
        <button className="graph-button" onClick={() => setPeriod('day')}>
          1 Day
        </button>
      </div>

      <Line data={data} options={options} />
      <div className="graph-buttons-legend flex items-center justify-end">
        <button className="graph-button" onClick={() => setUnit('day')}>
          Day Selector
        </button>
        <button className="graph-button" onClick={() => setUnit('hour')}>
          Hour Selector
        </button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="ml-0 size-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
          />
        </svg>
      </div>
    </div>
  );
}
