import CollectorGraphMeasurements from '@components/CollectorGraphMeasurements/CollectorGraphMeasurements.tsx';
import { InterfaceCollector } from '@interfaces/InterfaceCollector.ts';
import { DateTimePicker } from '@mantine/dates';
import { useAppDispatch, useAppSelector } from '@store/appStore.ts';
import {
  getCompanyCollectorMeasurement,
  selectCompanyCollectorAvailableMeasurements,
  selectCompanyCollectorMeasurement,
} from '@store/reducers/collectorReducer.ts';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { URLSearchParamsInit } from 'react-router-dom/dist/dom';

export type CollectorGraphMeasurementWrapperProps = {
  collector: InterfaceCollector;
  dataMeasurementTypeSlug: string;
};

export default function CollectorGraphMeasurementWrapper({
  collector,
  dataMeasurementTypeSlug,
}: CollectorGraphMeasurementWrapperProps) {
  console.log('test');
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyCollectorAvailableMeasurements = useAppSelector(
    selectCompanyCollectorAvailableMeasurements(collector.uuid),
  );
  let companyCollectorAvailableMeasurement;
  if (companyCollectorAvailableMeasurements && companyCollectorAvailableMeasurements[dataMeasurementTypeSlug]) {
    companyCollectorAvailableMeasurement = companyCollectorAvailableMeasurements[dataMeasurementTypeSlug];
  }

  const startOfDay = dayjs(searchParams.get('fromTimestamp') ?? undefined).startOf('day');

  let endOfDay = dayjs().endOf('day');
  if (startOfDay.isAfter(endOfDay)) {
    endOfDay = startOfDay.endOf('day');
  }

  const [fromTimestamp, setFromTimestamp] = useState<dayjs.Dayjs>(
    dayjs(searchParams.get('fromTimestamp') ?? startOfDay),
  );
  const [toTimestamp, setToTimestamp] = useState<dayjs.Dayjs>(dayjs(searchParams.get('toTimestamp') ?? endOfDay));

  useEffect(() => {
    dispatch(
      getCompanyCollectorMeasurement({
        urlVarsReplaceMap: {
          '{collectorUuid}': collector.uuid,
          '{measurementTypeSlug}': dataMeasurementTypeSlug,
          '{fromTimestamp}': fromTimestamp.toISOString(),
          '{toTimestamp}': toTimestamp.toISOString(),
        },
      }),
    );
  }, [fromTimestamp, toTimestamp, dataMeasurementTypeSlug, dispatch, collector]);

  useEffect(() => {
    const searchParms: URLSearchParamsInit = {};
    if (fromTimestamp.toISOString() !== searchParams.get('fromTimestamp')) {
      searchParms.fromTimestamp = fromTimestamp.toISOString();
    }
    if (toTimestamp.toISOString() !== searchParams.get('toTimestamp')) {
      searchParms.toTimestamp = toTimestamp.toISOString();
    }
    if (dataMeasurementTypeSlug !== searchParams.get('dataMeasurementTypeSlug')) {
      searchParms.dataMeasurementTypeSlug = dataMeasurementTypeSlug;
    }
    setSearchParams(searchParms);
  }, [setSearchParams, fromTimestamp, toTimestamp, dataMeasurementTypeSlug, searchParams]);

  const companyCollectorMeasurement = useAppSelector(selectCompanyCollectorMeasurement(collector.uuid));

  // TODO remove the datepicker from the URL params til later stage
  return companyCollectorAvailableMeasurement ? (
    <>
      <div className="hidden grid-cols-6">
        <div className="col-start-2">
          <DateTimePicker
            valueFormat="YYYY-MMM-DD HH:mm"
            value={fromTimestamp.toDate()}
            label="Pick from date"
            placeholder="Pick from date"
            onChange={(value) => value && setFromTimestamp(dayjs(value))}
            disabled
          />
        </div>
        <div className="col-start-4 col-end-5">
          <DateTimePicker
            valueFormat="YYYY-MMM-DD HH:mm"
            value={toTimestamp.toDate()}
            label="Pick to date"
            placeholder="Pick to date"
            onChange={(value) => value && setToTimestamp(dayjs(value))}
            disabled
          />
        </div>
      </div>
      <div className="grid grid-cols-1">
        {companyCollectorMeasurement && companyCollectorMeasurement.data ? (
          <CollectorGraphMeasurements collectorMeasurements={companyCollectorMeasurement} />
        ) : (
          <></>
        )}
      </div>
    </>
  ) : (
    <> </>
  );
}
