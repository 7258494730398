import { InterfaceCollector } from '@interfaces/InterfaceCollector.ts';
import { Table } from '@mantine/core';
import { useAppSelector } from '@store/appStore.ts';
import { selectCompanyCollectorParameters } from '@store/reducers/collectorReducer.ts';
import 'tailwindcss/tailwind.css';

export interface MachineInfoProps {
  collector: InterfaceCollector;
}

export default function MachineInfo({ collector }: MachineInfoProps) {
  const companyCollectorParameters = useAppSelector(selectCompanyCollectorParameters(collector.uuid)) ?? {};

  return (
    <div className="w-full">
      <Table>
        <thead>
          <tr>
            <th className="text-left">Name</th>
            <th className="text-left">Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(companyCollectorParameters).map((row, index) => (
            <tr key={index}>
              <td>{row.name}</td>
              <td>
                {row.value}
                {row.unit}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
