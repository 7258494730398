import AppConfig from '@config/AppConfig.ts';
import { InterfaceAggregators } from '@interfaces/InterfaceAggregators.ts';
import { InterfaceCollector } from '@interfaces/InterfaceCollector.ts';
import { InterfaceCollectorAvailableMeasurement } from '@interfaces/InterfaceCollectorAvailableMeasurement.ts';
import { InterfaceCollectorMeasurements } from '@interfaces/InterfaceCollectorMeasurements.ts';
import { InterfaceCollectorParameter } from '@interfaces/InterfaceCollectorParameter.ts';
import { InterfaceCompanyFarm } from '@interfaces/InterfaceCompanyFarm.ts';
import { InterfaceGenericMap } from '@interfaces/InterfaceGenericMap.ts';
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore';
import { meLogOut } from '@store/reducers/meReducer.ts';
import createDebouncedAsyncThunk from '@store/reducers/reducerHelpers/createDebouncedAsyncThunk.ts';
import { sliceStateCheckAndUpdate } from '@store/reducers/reducerHelpers/sliceStateCheckAndUpdate.ts';
import thunkGet from '@store/reducers/reducerHelpers/thunkGet.ts';
import { defaultTypeDebounceTimeMs } from '@store/reducers/reducerHelpers/thunkHelperShared.ts';
import memoize from 'lodash-es/memoize';
import OpenWeatherMap from 'openweathermap-ts';
import { CurrentResponse } from 'openweathermap-ts/dist/types/CurrentReponse';

export interface DataReducerState {
  companyFarms: InterfaceGenericMap<InterfaceCompanyFarm>;
  companyAggregators: InterfaceGenericMap<InterfaceAggregators>;
  companyCollectors: InterfaceGenericMap<InterfaceCollector>;
  companyCollectorParameters: InterfaceGenericMap<InterfaceGenericMap<InterfaceCollectorParameter>>;
  companyCollectorAvailableMeasurements: InterfaceGenericMap<
    InterfaceGenericMap<InterfaceCollectorAvailableMeasurement>
  >;
  companyCollectorMeasurements: InterfaceGenericMap<InterfaceCollectorMeasurements>;
  collectorsWeather: InterfaceGenericMap<InterfaceCollectorWeatherData>;
}

const initialState: DataReducerState = {
  companyFarms: {},
  companyAggregators: {},
  companyCollectors: {},
  companyCollectorParameters: {},
  companyCollectorAvailableMeasurements: {},
  companyCollectorMeasurements: {},
  collectorsWeather: {},
};

export const sliceName = 'collector';

export const collectorSlice = createSlice({
  name: sliceName, // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Use `extraReducers` to handle actions that were generated
    // _outside_ of the slice, such as thunks or in other slices
    builder
      .addCase(getCompanyFarms.fulfilled, sliceStateCheckAndUpdate.sliceItem('companyFarms'))
      .addCase(getCompanyAggregators.fulfilled, sliceStateCheckAndUpdate.sliceItem('companyAggregators'))
      .addCase(getCompanyCollectors.fulfilled, sliceStateCheckAndUpdate.sliceItem('companyCollectors'))
      .addCase(
        getCompanyCollectorParams.fulfilled,
        sliceStateCheckAndUpdate.sliceItemElementUuid('companyCollectorParameters'),
      )
      .addCase(getCompanyAllCollectorParams.fulfilled, sliceStateCheckAndUpdate.sliceItem('companyCollectorParameters'))
      .addCase(
        getCompanyAllCollectorAvailableMeasurements.fulfilled,
        sliceStateCheckAndUpdate.sliceItem('companyCollectorAvailableMeasurements'),
      )
      .addCase(
        getCompanyCollectorMeasurement.fulfilled,
        sliceStateCheckAndUpdate.sliceItemElementGeneric('companyCollectorMeasurements', 'collectorUuid'),
      )
      .addCase(getCompanyCollectorWeather.fulfilled, sliceStateCheckAndUpdate.sliceItemElementUuid('collectorsWeather'))
      .addCase(meLogOut.fulfilled, () => initialState);
  },
});

export const getCompanyFarms = thunkGet.compUrl(sliceName, `c/{companySlug}/admin/farm`);

export const selectNumberOfFarms = (state: AppState) => {
  const companyFarmsMap: InterfaceGenericMap<InterfaceCompanyFarm> = selectCompanyFarms(state);
  const companyFarmsArray: InterfaceCompanyFarm[] = Object.values(companyFarmsMap);
  return companyFarmsArray.length;
};

// TODO Possibly will use this in future (number of collectors per selected farm)

// export const selectNumberOfCollectorsForFarm = (state: AppState, farmUuid: string) => {
//   const companyCollectorsMap: InterfaceGenericMap<InterfaceCollector> = selectCompanyCollectors(state);
//   const companyCollectorsArray: InterfaceCollector[] = Object.values(companyCollectorsMap);
//   const collectorsForFarm = companyCollectorsArray.filter((collector) => collector.farmUuid === farmUuid);
//   return collectorsForFarm.length;
// };

export const selectNumberOfCollectors = (state: AppState) => {
  const companyCollectorsMap: InterfaceGenericMap<InterfaceCollector> = selectCompanyCollectors(state);
  const companyCollectorsArray: InterfaceCollector[] = Object.values(companyCollectorsMap);
  return companyCollectorsArray.length;
};

interface InterfaceCompanyAggregatorReplace {
  '{farmUuid}': string;
}

export const getCompanyAggregators = thunkGet.compUrl<InterfaceCompanyAggregatorReplace>(
  sliceName,
  `c/{companySlug}/admin/aggregator`,
);

export const getCompanyCollectors = thunkGet.compUrl(sliceName, `c/{companySlug}/admin/collector`);

export interface InterfaceCompanyCollectorParamsReplace {
  '{collectorUuid}': string;
}

export const getCompanyCollectorParams = thunkGet.compUrl<InterfaceCompanyCollectorParamsReplace>(
  sliceName,
  `c/{companySlug}/admin/collector/{collectorUuid}/parameter`,
);

export const getCompanyAllCollectorParams = thunkGet.compUrl(sliceName, `c/{companySlug}/admin/collector/parameter`);

export const getCompanyAllCollectorAvailableMeasurements = thunkGet.compUrl(
  sliceName,
  `c/{companySlug}/admin/collector/measurement/available`,
);

export interface InterfaceCompanyCollectorMeasurementReplace {
  '{collectorUuid}': string;
  '{measurementTypeSlug}': string;
  '{fromTimestamp}': string;
  '{toTimestamp}': string;
}

export const getCompanyCollectorMeasurement = thunkGet.compUrl<InterfaceCompanyCollectorMeasurementReplace>(
  sliceName,
  `c/{companySlug}/admin/collector/{collectorUuid}/measurement/{measurementTypeSlug}?from={fromTimestamp}&to={toTimestamp}`,
);

export interface InterfaceGetCompanyCollectorWeather {
  collector: InterfaceCollector;
}

export interface InterfaceCollectorWeatherData {
  uuid: string;
  weatherData: CurrentResponse;
}

export const getCompanyCollectorWeather = createDebouncedAsyncThunk(
  `${sliceName}/getCompanyCollectorWeather`,
  async (data: InterfaceGetCompanyCollectorWeather, { getState }): Promise<InterfaceCollectorWeatherData | null> => {
    if (data.collector.location && data.collector.location.lat && data.collector.location.lng) {
      const state: AppState = getState() as AppState;
      const collectorLocation = selectCompanyCollector(data.collector.uuid)(state).location ?? {
        lat: data.collector.location.lat,
        lng: data.collector.location.lng,
      };

      const openWeather = new OpenWeatherMap({
        apiKey: AppConfig.weather.apiKey,
      });

      const weather = await openWeather.getCurrentWeatherByGeoCoordinates(collectorLocation.lat, collectorLocation.lng);

      return {
        uuid: data.collector.uuid,
        weatherData: weather,
      };
    }
    return null;
  },
  defaultTypeDebounceTimeMs,
);

// export const { } = meSlice.actions;

export const selectCollectorState = (state: AppState) => state.collector;
export const selectCompanyFarms = (state: AppState) => selectCollectorState(state).companyFarms;
export const selectCompanyAggregators = (state: AppState) => selectCollectorState(state).companyAggregators;
export const selectCompanyCollectors = (state: AppState) => selectCollectorState(state).companyCollectors;

const selectCompanyCollectorsByFarmWrapper = memoize((state: AppState, farmUuid: string) =>
  Object.fromEntries(
    Object.entries(selectCompanyCollectors(state)).filter(
      ([, companyCollector]) => companyCollector.farmUuid === farmUuid,
    ),
  ),
);

export const selectCompanyCollectorsByFarm = (farmUuid: string) => (state: AppState) =>
  selectCompanyCollectorsByFarmWrapper(state, farmUuid);

export const selectCompanyCollector = (collectorUuid: string) => (state: AppState) =>
  selectCompanyCollectors(state)[collectorUuid];
export const selectCompanyCollectorsParameters = (state: AppState) =>
  selectCollectorState(state).companyCollectorParameters;

const selectCompanyCollectorParametersWrapper = memoize(
  (collectorUuid: string, state: AppState) => selectCompanyCollectorsParameters(state)[collectorUuid] ?? {},
);

export const selectCompanyCollectorParameters = (collectorUuid: string) => (state: AppState) =>
  selectCompanyCollectorParametersWrapper(collectorUuid, state);

export const selectCompanyCollectorsAvailableMeasurements = (state: AppState) =>
  selectCollectorState(state).companyCollectorAvailableMeasurements;
export const selectCompanyCollectorAvailableMeasurements = (collectorUuid: string) => (state: AppState) =>
  selectCompanyCollectorsAvailableMeasurements(state)[collectorUuid] ?? {};

export const selectCompanyCollectorsMeasurements = (state: AppState) =>
  selectCollectorState(state).companyCollectorParameters;
export const selectCompanyCollectorsWeather = (state: AppState) => selectCollectorState(state).collectorsWeather;

const selectCompanyCollectorWeatherWrapper = memoize(
  (collectorUuid: string, state: AppState) => selectCompanyCollectorsWeather(state)[collectorUuid] ?? {},
);

export const selectCompanyCollectorWeather = (collectorUuid: string) => (state: AppState) =>
  selectCompanyCollectorWeatherWrapper(collectorUuid, state);

export const selectCompanyCollectorMeasurements = (state: AppState) =>
  selectCollectorState(state).companyCollectorMeasurements;

// const selectCompanyCollectorMeasurementRaw = (collectorUuid: string) => (state: AppState) =>
//   selectCompanyCollectorMeasurements(state)[collectorUuid] ?? {};

const selectCompanyCollectorMeasurementWrapper = memoize(
  (collectorUuid: string, state: AppState) => selectCompanyCollectorMeasurements(state)[collectorUuid] ?? {},
);

export const selectCompanyCollectorMeasurement = (collectorUuid: string) => (state: AppState) =>
  selectCompanyCollectorMeasurementWrapper(collectorUuid, state);

export default collectorSlice.reducer;
