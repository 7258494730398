import App from '@app/App';
import SideNav from '@layouts/SideNav';
import { Outlet } from 'react-router';

const DefaultLayout = () => {
  return (
    <App>
      <SideNav />
      <div className="dark:text-white-dark min-h-screen bg-gray-100 text-black">
        <Outlet />
      </div>
    </App>
  );
};

export default DefaultLayout;
